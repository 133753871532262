import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { Container } from "../global"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      help: file(sourceInstanceName: { eq: "product" }, name: { eq: "CodeDing-MR_Help_Settings" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      settings: file(sourceInstanceName: { eq: "product" }, name: { eq: "CodeDing-MR_Notification_Settings" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <WorkflowWrapper>
      <Container>
        <Flex>
          <ImageWrapper>
            <StyledImage fluid={data.help.childImageSharp.fluid} />
            <br />
          </ImageWrapper>
          <ImageWrapper>
            <StyledImage fluid={data.settings.childImageSharp.fluid} />
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </WorkflowWrapper>
  )
}

export default Header

const WorkflowWrapper = styled.header`
  background-color: #ffffff;
  padding: 60px 0 0 0;
  position: relative;
  @media (max-width: ${props => props.theme.screen.md}) {
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 500px;
  border-radius: 5px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`
