import React from "react";
import styled from "styled-components";

import { Section, Container } from "../global";

const Faq = () => (
  <Section id="faq">
    <StyledContainer>
      <Subtitle>Frequently Asked Questions</Subtitle>
      <FaqGrid>
        <FaqItem>
          <FaqTitle>
            Are there differences between Gitlab Free tier and Paid?
          </FaqTitle>
          <FaqText>
            There is one major difference between Gitlab Free and Paid versions.
            You can only combine CodeDing with one of your projects on the
            Gitlab Free tier, where on the Gitlab Paid plan you can combine it
            with your entire group of projects.
          </FaqText>
        </FaqItem>
        <FaqItem>
          <FaqTitle>Do you offer a free plan?</FaqTitle>
          <FaqText>
            We don't currently offer a free plan, but if you are a non-profit or
            open source, reach out and we can help! We built this tool because
            we needed a Gitlab/Slack MR tool for our own projects that worked
            consistently, so believe us when we say that it is well worth the
            money!
          </FaqText>
        </FaqItem>
        <FaqItem>
          <FaqTitle>When will I be billed?</FaqTitle>
          <FaqText>
            Slightly before the end of your 21 day free trial, you will be
            notified with the way to pay. At that time, you will be billed every
            month on the same day you finalized your payment information.
          </FaqText>
        </FaqItem>
        <FaqItem>
          <FaqTitle>Do you support Gitlab Self-hosted</FaqTitle>
          <FaqText>That will be coming soon! Stay tuned.</FaqText>
        </FaqItem>
      </FaqGrid>
    </StyledContainer>
  </Section>
);

export default Faq;

const StyledContainer = styled(Container)``;

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`;

const FaqGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`;

const FaqItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const FaqTitle = styled.h4`
  font-size: 24px;
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`;

const FaqText = styled.p`
  font-size: 18px;
  text-align: center;
`;
