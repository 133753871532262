import React, { useEffect, useState, useRef } from "react";
import ReactModal from "react-modal";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";
import { Link } from "gatsby";
import styled from "styled-components";

import { Section, Container, ModalContainer } from "../global";

ReactModal.setAppElement("#___gatsby");

const customStyles = {
  content: {
    width: "800px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Pricing = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const captchaResponse = useRef(null);
  const teamId = useRef(null);

  const [modalState, setModalState] = useState({
    isModalOpen: false,
  });

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const [queryParams, setQueryParams] = useState({
    contactParam: "",
    tidParam: "",
  });

  useEffect(() => {
    const params = new URLSearchParams(document.location.search.substring(1));
    const contact = parseInt(params.get("contact"), 10);
    const tid = params.get("tid");

    setQueryParams({
      contactParam: contact,
      tidParam: tid,
    });

    if (contact) {
      setModalState({ isModalOpen: true });
    }
  }, [queryParams.contact]);

  const handleModalOpen = () => {
    setModalState({ isModalOpen: true });
  };

  const handleModalClose = () => {
    setModalState({ isModalOpen: false });
  };

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });

    if (ok) form.reset();
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    if (!executeRecaptcha) {
      return;
    }

    const form = event.target;

    setServerState({
      submitting: true,
    });

    const result = await executeRecaptcha("homepage");

    captchaResponse.current.value = result;

    if (queryParams.tidParam) {
      teamId.current.value = queryParams.tidParam;
    }

    axios({
      method: "post",
      url: "https://getform.io/f/1fa15148-0fdc-4ea0-bc43-dbe72db6aa39",
      data: new FormData(form),
    })
      .then((response) => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch((response) => {
        handleServerResponse(false, response.response.data.error, form);
      });
  };

  return (
    <Section id="pricing">
      <StyledContainer>
        <Subtitle>Pricing</Subtitle>
        <SectionTitle>How much is it going to cost?</SectionTitle>
        <PricingGrid>
          <PricingItem>
            <PricingTitle>Non Profit</PricingTitle>
            <PricingDescription>Open Source</PricingDescription>
            <PricingDescription>Reach out</PricingDescription>
            <PricingDescription>We can help!</PricingDescription>
            <PricingDescription>
              <Link to="#pricing" onClick={handleModalOpen}>
                <ReachOutButton>Contact</ReachOutButton>
              </Link>
            </PricingDescription>
          </PricingItem>
          <PricingItem>
            <PricingTitle>Teams</PricingTitle>
            <PricingDescription>2-4 seats $15/month</PricingDescription>
            <PricingDescription>5-10 seats $25/month</PricingDescription>
            <PricingDescription>11-20 seats $35/month</PricingDescription>
            <PricingDescriptionLast>
              21-30 seats $45/month
            </PricingDescriptionLast>
          </PricingItem>
          <PricingItem>
            <PricingTitle>Enterprise</PricingTitle>
            <PricingDescription>30+ seats</PricingDescription>
            <PricingDescription>Reach out</PricingDescription>
            <PricingDescription>Discuss pricing</PricingDescription>
            <PricingDescription>
              <Link to="#pricing" onClick={handleModalOpen}>
                <ReachOutButton>Contact</ReachOutButton>
              </Link>
            </PricingDescription>
          </PricingItem>
        </PricingGrid>
        <GetStartedContainer>
          <Link to="#pricing" onClick={handleModalOpen}>
            <ReachOutButton>Support</ReachOutButton>
          </Link>
          <TryItButton>
            <a href="https://slack.com/oauth/v2/authorize?scope=channels%3Aread%2Cchannels%3Ajoin%2Cchat%3Awrite.public%2Cchat%3Awrite%2Ccommands%2Cteam%3Aread%2Cusergroups%3Aread%2Cusers%3Aread&state=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbnN0YWxsT3B0aW9ucyI6eyJzY29wZXMiOlsiY2hhbm5lbHM6cmVhZCIsImNoYW5uZWxzOmpvaW4iLCJjaGF0OndyaXRlLnB1YmxpYyIsImNoYXQ6d3JpdGUiLCJjb21tYW5kcyIsInRlYW06cmVhZCIsInVzZXJncm91cHM6cmVhZCIsInVzZXJzOnJlYWQiXX0sIm5vdyI6IjIwMjAtMTAtMjdUMTM6MjE6MjAuNDAxWiIsImlhdCI6MTYwMzgwNDg4MH0.tpIxFCuJBF5NqQCxS2Trky6K8UlEKUhl3E4o4ppy7z4&client_id=1325085587462.1341848607844">
              Sign Up
            </a>
          </TryItButton>
        </GetStartedContainer>
        <ReactModal
          isOpen={modalState.isModalOpen}
          onRequestClose={handleModalClose}
          style={customStyles}
          contentLabel="CodeDing Contact Form"
        >
          <ModalContainer>
            <ModalCloseButton onClick={handleModalClose}>
              Close
            </ModalCloseButton>
            <form onSubmit={handleOnSubmit}>
              <label htmlFor="name">First Name</label>
              <input
                type="text"
                id="name"
                name="nameInput"
                placeholder="Your full name"
              />

              <label htmlFor="email">Email *</label>
              <input
                type="email"
                id="email"
                name="emailInput"
                placeholder="Your email"
                required
              />

              <label htmlFor="organization">Organization</label>
              <input
                type="text"
                id="organization"
                name="organizationInput"
                placeholder="Your organization"
              />

              <label htmlFor="reason">What are you reaching out about?</label>
              <select id="reason" name="reason">
                <option value="support">Support</option>
                <option value="nonprofit">
                  Non Profit &amp; Open Source Pricing
                </option>
                <option value="enterprise">Enterprise Pricing</option>
              </select>

              <label htmlFor="subject" required="required">
                Subject *
              </label>
              <textarea
                id="subject"
                name="subject"
                placeholder="Write something.."
                style={{ height: "100px" }}
                required
              ></textarea>
              <input
                type="hidden"
                id="captchaResponse"
                name="g-recaptcha-response"
                ref={captchaResponse}
              ></input>
              <input type="hidden" id="teamId" name="team" ref={teamId}></input>
              <input
                type="submit"
                value="Submit"
                disabled={serverState.submitting}
              />
              {serverState.status && <p>{serverState.status.msg}</p>}
            </form>
          </ModalContainer>
        </ReactModal>
      </StyledContainer>
    </Section>
  );
};

export default Pricing;

const StyledContainer = styled(Container)`
  a {
    text-decoration: none;
    color: #fff;
  }
`;

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 40px 0 40px;
  a {
    text-decoration: none;
    color: #fff;
  }
`;

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`;

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`;

const PricingGrid = styled.div`
  max-width: 770px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`;

const PricingItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.background.lightdarker};
`;

const PricingTitle = styled.h4`
  background-color: ${(props) => props.theme.color.primarydark};
  width: 100%;
  color: ${(props) => props.theme.color.white.darker};
  letter-spacing: 0px;
  line-height: 30px;
  text-align: center;
  padding: 30px;
  margin: 0px 0px 40px 0px;
`;
const PricingDescription = styled.h5`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  text-align: center;
  margin: 30px;
`;

const PricingDescriptionLast = styled.h5`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  text-align: center;
  margin: 30px 30px 60px 30px;
`;

const TryItButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 65px;
  display: block;
  margin-left: 45px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`;

const ReachOutButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 48px;
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.logo.orange};
  border-radius: 4px;
  padding: 0px 22px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`;

const ModalCloseButton = styled.button`
  float: right;
  overflow: hidden;
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 48px;
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 22px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`;
