import React from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";

import Header from "../components/sections/header";
import Faq from "../components/sections/faq";
import Features from "../components/sections/features";
import Workflow from "../components/sections/workflow";
import Footer from "../components/sections/footer";
import Pricing from "../components/sections/pricing";
import GetStarted from "../components/sections/getstarted";

const IndexPage = () => (
  <Layout>
    <SEO title="CodeDing - Merge Requests you see" />
    <Navigation />
    <Header />
    <Features />
    <Workflow />
    <GetStarted />
    <Pricing />
    <Faq />
    <Footer />
  </Layout>
);

export default IndexPage;
